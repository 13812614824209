import React from 'react';
import {
  Grid,
  TextField,
  FormGroup,
  InputAdornment,
  Typography,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import Data from './static/data.json';

const App: React.FC = () => {
  const [filteredForces, setFilteredForces] = React.useState(Data.specialForces);
  const [searchString, setSearchString] = React.useState('');

  const handleSearch = (value: string) => {
    setSearchString(value);
    if (value === '') {
      setFilteredForces(Data.specialForces);
    }

    const specialForces = Data.specialForces.filter(x =>
      x.people.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredForces(specialForces);
  };

  return (
    <Grid container justify="center" style={{ marginTop: '20px' }}>
      <Grid container justify="center">
        <Grid item lg={10} md={12} sm={12} xs={12}>
          <FormGroup>
            <TextField
              label="Suche"
              variant="outlined"
              value={searchString}
              onChange={event => handleSearch(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
            />
          </FormGroup>
        </Grid>
        {filteredForces.map((force, index) => (
          <Grid
            item
            lg={10}
            md={12}
            sm={12}
            xs={12}
            key={`force_${index}`}
            style={{ marginTop: '6px', marginBottom: '6px' }}
          >
            <Card title={force.people}>
              <CardHeader title={force.people} subheader={force.specialForce} />
              <CardContent>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6}>
                    <Typography variant="caption">Stark gegen</Typography>
                    <List>
                      {force.strength.map((strength, strengthIndex) => (
                        <ListItem key={`force_${index}_strength_${strengthIndex}`}>
                          <ListItemText primary={strength} />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Typography variant="caption">Schwach gegen</Typography>
                    <List>
                      {force.weakness.map((weakness, weaknessIndex) => (
                        <ListItem key={`force_${index}_weakness_${weaknessIndex}`}>
                          <ListItemText primary={weakness} />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default App;
