import { createMuiTheme } from '@material-ui/core/styles';
import { lightBlue } from '@material-ui/core/colors';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#ba000d'
    },
    secondary: lightBlue,
    type: 'dark'
  }
});
